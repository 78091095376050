module.exports = [{
      plugin: require('/Users/mgenov/src/github.com/clouway/tools/public-portal/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1500,"wrapperStyle":"box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);","linkImagesToOriginal":false},
    },{
      plugin: require('/Users/mgenov/src/github.com/clouway/tools/public-portal/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/Users/mgenov/src/github.com/clouway/tools/public-portal/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/mgenov/src/github.com/clouway/tools/public-portal/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-85799539-3","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/mgenov/src/github.com/clouway/tools/public-portal/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
